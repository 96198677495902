<template>
  <div class="block-body" style="position: relative;">
    <div class="company-list-scroll">
        <div class="left-scroll-button" @click="prev"></div>
        <el-carousel class="my-carousel"
                     :autoplay="false" :loop="false" arrow="never" ref="carousel">
          <el-carousel-item v-for="chunk in chunkedReports" :key="chunk[0].id">
            <div class="icca-list">
              <ul>
                <li v-for="item in chunk.splice(0,10)" :key="item.id">
                  <div class="li_div">
                    <a class="link" v-if="item.company==true && item.entType=='1'" :href="$router.resolve({path:'/company/ThirdDetails',query:{companyName:item.name}}).href">{{item.name}}</a>
                    <a class="content" v-else @onclick="return false">{{item.name}}</a>
                  </div>
                </li>
              </ul>
              <ul>
                <li v-for="item in chunk.splice(0,10)" :key="item.id">
                  <div class="li_div">
<!--                    <div class="content" >{{ item.name}}</div>-->
                    <a class="link" v-if="item.company==true && item.entType=='1'" :href="$router.resolve({path:'/company/ThirdDetails',query:{companyName:item.name}}).href">{{item.name}}</a>
                    <a class="content" v-else @onclick="return false">{{item.name}}</a>
                  </div>
                </li>
              </ul>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="right-scroll-button"  @click="next"></div>
    </div>
  </div>
</template>

<script>
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import api from "../api";

export default {
  name: "UfiCertifiedMember",
  components: {},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      data: [],
    }
  },
  computed: {
    chunkedReports() {
      // 每次显示4个卡片
      const chunkSize = 20;
      const chunks = [];
      for (let i = 0; i < this.data.length; i += chunkSize) {
        chunks.push(this.data.slice(i, i + chunkSize));
      }
      return chunks;
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.loadData()
    },
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    async loadData(){
      let params= {
        entityType: [2]
      }
      this.data=(await api.listEntity(params)).data
    }
  }
}
</script>

<style scoped>
.icca-list{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 20px;
}
ul {
  list-style: none; /* 移除默认的列表标记 */
  padding: 0px; /* 移除默认的内边距 */
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
li {
  position: relative; /* 为伪元素定位做准备 */
  padding-left: 20px; /* 留出空间显示自定义标记 */
  padding-bottom: 14px;
}
li::before {
  content: "•"; /* 自定义标记，这里是一个实心圆点 */
  font-size: 20px;
  position: absolute; /* 绝对定位，相对于其父元素（li） */
  left: 0; /* 放置在列表项文本的左侧 */
  color: #BFD9F4; /* 设置标记颜色 */
}
.li_div{
  width: 430px;
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
  flex-wrap: wrap;
}
.content {
  /* 可以在这里添加具体内容的样式 */
}
.content1 {
  /* 可以在这里添加具体内容的样式 */
  color: #555555;
}
.my-carousel{
  width: 100%;
  padding: 10px 20px;
}
.my-carousel ::v-deep .el-carousel__container{
  height: 320px !important;
}
</style>
