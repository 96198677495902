<template>
  <div class="block-body" style="position: relative;">
    <div class="company-list-scroll">
      <div class="left-scroll-button" @click="prev"></div>
<!--      <div class="icca-list">
        <ul>
          <li v-for="item in data" :key="item.id">
            <div class="li_div">
              <div class="content">{{ item.name}}</div>
              <div class="content1">{{ item.busType}}</div>
            </div>
          </li>
        </ul>
        <ul>
          <li v-for="item in data1" :key="item.id">
            <div class="li_div">
              <div class="content">{{ item.name}}</div>
              <div class="content1">{{ item.busType}}</div>
            </div>
          </li>
        </ul>
      </div>-->
      <el-carousel class="my-carousel"
                   :autoplay="false" :loop="false" arrow="never" ref="carousel">
        <el-carousel-item v-for="chunk in chunkedReports" :key="chunk[0].id">
          <div class="icca-list">
            <ul>
              <li v-for="item in chunk.splice(0,10)" :key="item.id">
                <div class="li_div">
                  <a class="link" v-if="item.company==true && item.entType=='1'" :href="$router.resolve({path:'/company/ThirdDetails',query:{companyName:item.name}}).href">{{item.name}}</a>
                  <a class="content" v-else @onclick="return false">{{item.name}}</a>
                </div>
              </li>
            </ul>
            <ul>
              <li v-for="item in chunk.splice(0,10)" :key="item.id">
                <div class="li_div">
                  <!--                    <div class="content" >{{ item.name}}</div>-->
                  <a class="link" v-if="item.company==true && item.entType=='1'" :href="$router.resolve({path:'/company/ThirdDetails',query:{companyName:item.name}}).href">{{item.name}}</a>
                  <a class="content" v-else @onclick="return false">{{item.name}}</a>
                </div>
              </li>
            </ul>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="right-scroll-button" @click="next"></div>
    </div>
  </div>
</template>

<script>
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import api from "../api";

export default {
  name: "IccaMember",
  components: {},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  computed: {
    chunkedReports() {
      // 每次显示4个卡片
      const chunkSize = 20;
      const chunks = [];
      for (let i = 0; i < this.data.length; i += chunkSize) {
        chunks.push(this.data.slice(i, i + chunkSize));
      }
      return chunks;
    }
  },
  data() {
    return {
      data: [
        {
          id: 165726,
          name: '中国国航',
          busType: '交通',
        },
        {
          id: 165572,
          name: '北京国际会议中心',
          busType: '场地',
        },
        {
          id: 723177,
          name: '北京市文化和旅游局',
          busType: '目的地营销',
        },
        {
          id: 165529,
          name: '北京雁栖湖国际会展中心',
          busType: '场地',
        },
        {
          id: 165068,
          name: '中青博联整合营销顾问股份有限公司',
          busType: '会议管理',
        },
        {
          id: 165653,
          name: '中国国际科技交流中心',
          busType: '会议管理',
        },
        {
          id: 165654,
          name: '国家会议中心',
          busType: '场地',
        },
        {
          id: 165655,
          name: '邮电国际旅行社有限责任公司',
          busType: '会议管理',
        },
        {
          id: 165656,
          name: '北京欣欣翼翔国际会议有限公司',
          busType: '会议管理',
        },
        {
          id: 165657,
          name: '康辉集团国际会议展览有限公司',
          busType: '会议管理',
        }
      ],
      data1: [
        {
          id: 165726,
          name: '中旅国际会议展览有限公合',
          busType: '会议管理',
        },
        {
          id: 165572,
          name: '北京昆仑亿发科技股份有限公司',
          busType: '会议管理',
        },
        {
          id: 723177,
          name: '博悦咨询(北京)有限公司',
          busType: '目的地营销',
        },
        {
          id: 165529,
          name: '北京锋滔会展服务有限公司',
          busType: '会议管理',
        },
        {
          id: 165068,
          name: '湖南省会议接待服务中心',
          busType: '会议管理',
        },
        {
          id: 165653,
          name: '湖南帝爵国际会展服务有限公司',
          busType: '会议管理',
        },
        {
          id: 165654,
          name: '湖南锐智国际会议展览服务有限公司',
          busType: '会议管理',
        },
        {
          id: 165655,
          name: '湖南省阳光会议展览服务有限公司',
          busType: '会议管理',
        },
        {
          id: 165656,
          name: '成都励翔文化创意股份有限公司',
          busType: '目的地营销',
        },
        {
          id: 165657,
          name: '成都市博览局',
          busType: '会议管理',
        }
      ],
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.loadData()
    },
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    async loadData(){
      let params= {
        entityType: [1]
      }
      this.data=(await api.listEntity(params)).data
    }
  }
}
</script>

<style scoped>
  .icca-list{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 20px;
  }
  ul {
    list-style: none; /* 移除默认的列表标记 */
    padding: 0px; /* 移除默认的内边距 */
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }
  li {
    position: relative; /* 为伪元素定位做准备 */
    padding-left: 20px; /* 留出空间显示自定义标记 */
    padding-bottom: 14px;
  }
  li::before {
    content: "•"; /* 自定义标记，这里是一个实心圆点 */
    position: absolute; /* 绝对定位，相对于其父元素（li） */
    left: 0; /* 放置在列表项文本的左侧 */
    color: #BFD9F4; /* 设置标记颜色 */
  }
  .li_div{
    width: 410px;
    display: flex;
    justify-content: space-between;
    /*align-items: center;*/
    flex-wrap: wrap;
  }
  .content {
    /* 可以在这里添加具体内容的样式 */
  }
  .content1 {
    /* 可以在这里添加具体内容的样式 */
    color: #555555;
  }
  .my-carousel{
    width: 100%;
    padding: 10px 20px;
  }
  .my-carousel ::v-deep .el-carousel__container{
    height: 320px !important;
  }
</style>
