<template>
  <block title="会展领域股权融资" v-if="data">
    <el-table :data="data.content" tooltip-effect="light" class="large-font-table">
      <el-table-column label="项目">
        <div slot-scope="{row, $index}"  class="company-funding-logo-name">
          <canvas v-if="row.company.logo.indexOf('company_logo')>-1"
                  :id="'canvas-' + row.company.id + '-'+$index" width="32" height="32" ref="canvas"></canvas>
          <el-image v-else :src="$api.getAttachmentUrl(row.company.logo)" lazy fit="fill"></el-image>
          <span>{{ row.company.shortName || row.company.name }}</span>
        </div>
      </el-table-column>
      <el-table-column label="业务" prop="company.brief" show-overflow-tooltip></el-table-column>
      <el-table-column label="行业领域" width="140px" show-overflow-tooltip>
        <span slot-scope="{row}">{{row.company.firstLevelTags?row.company.firstLevelTags.join(','):''}}</span>
      </el-table-column>
      <el-table-column label="地区" prop="company.location" show-overflow-tooltip width="60px"></el-table-column>
      <el-table-column label="投资轮次" prop="roundName" show-overflow-tooltip width="90px"></el-table-column>
      <el-table-column label="投资时间" prop="fundingDate" show-overflow-tooltip width="120px"></el-table-column>
      <el-table-column label="投资金额" show-overflow-tooltip width="110px">
        <span slot-scope="{row}">{{formatMoney(row.formatInvestment,row.precise)}}{{row.formatInvestment?row.currencyName:''}}</span>
      </el-table-column>
      <el-table-column label="投资方" show-overflow-tooltip>
        <span slot-scope="{row}">{{ row.investors ? row.investors.map(i => i.shortName || i.name).join('、') : row.investorsRaw }}</span>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev, pager, next" background
                   :total="data.totalElements" :current-page="data.number+1"
                   @current-change="loadData">
    </el-pagination>
  </block>
</template>

<script>
import Block from "./Block";
import api from "../api";
import AttachmentAttrDirective from "./AttachmentAttrDirective";

export default {
  name: "CompanyFundingListBlock",
  components: {Block},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      data: null
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.loadData(1)
    },
    async loadData(page) {
      const params = {
        page: page - 1,
        size: 10,
      }
      this.data = (await api.listFunding(params)).data
      // 等待 Vue 完成 DOM 更新后，生成图标
      this.$nextTick(() => {
        this.generateLogos(); // 生成图标
      });
    },
    // 生成图标
    generateLogos() {
      // 遍历所有公司数据并生成图标
      this.data.content.forEach((row, index) => {
        if(row.company.logo.indexOf("company_logo_")!=-1){
          this.generateLogo(row.company.id, index ,row.company.name, row.company.shortName);
        }
      });
    },
    formatMoney(irInvestment, precise) {
      if (!irInvestment) {
        return '未知';
      } else if (irInvestment >= 100000000) {
        if (precise) {
          return (irInvestment / 100000000.0) + '亿';
        } else {
          return '数亿';
        }

      } else {
        if (precise) {
          return (irInvestment / 10000.0) + '万';
        } else {
          if (irInvestment >= 10000000) return '数千万';
          else if (irInvestment >= 1000000) return '数百万';
          else if (irInvestment >= 100000) return '数十万';
          else if (irInvestment >= 10000) return '数万';
        }

      }
    },
    generateLogo(id, index, name, shortName) {
      const canvas = document.getElementById('canvas-' + id + '-' +index);  // 获取canvas元素
      var ctx = canvas.getContext('2d');
      // 获取设备的像素比
      const scale = 1;

      // 设置canvas的物理分辨率（提高细节）
      const width = canvas.width * scale;
      const height = canvas.height * scale;

      // 设置canvas的样式大小
      canvas.style.width = canvas.width + 'px';
      canvas.style.height = canvas.height + 'px';

      // 更新canvas的实际尺寸
      canvas.width = width;
      canvas.height = height;

      // 使用设置后的分辨率来绘制图像
      ctx.scale(scale, scale);  // 缩放绘图环境以适应显示尺寸

      // 设置背景色
      ctx.fillStyle = '#e7e7df';  // 背景颜色
      ctx.fillRect(0, 0, width, height);

      // 设置文本颜色
      ctx.fillStyle = '#00a881';  // 文字颜色

      // 设置文本内容
      const text = shortName || name || '';

      if (text) {
        // 自动调整字体大小以适应画布
        this.fitTextToCanvas(ctx, text, width, height);
      }
    },
    fitTextToCanvas(ctx, text, canvasWidth, canvasHeight) {
      var fontSize = 8;  // 初始字体大小
      ctx.font = fontSize + 'px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      // 调整字体大小直到文本适合画布
      while (ctx.measureText(text).width > canvasWidth - 10 && fontSize > 10) {
        fontSize--;
        ctx.font = fontSize + 'px Arial';
      }
      // 将文本绘制到画布中
      ctx.fillText(text, canvasWidth / 2, canvasHeight / 2);
    }
  }
}
</script>

<style scoped>

</style>
