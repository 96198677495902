<template>
  <div class="page-content" v-if="data">
<!--    <div style="background-color: #FFFFFF;padding: 20px;">
      <img src="../assets/images/ss/company-13.png" style="width: 100%">
    </div>-->
    <div class="search-form" style="margin-top: 10px;">
      <div class="search-form-row1">
        <el-input v-model="searchForm.keyword" style="width: 500px;margin-right: 12px;" placeholder="关键词"></el-input>
        <el-button type="primary" @click="search">检索</el-button>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">会展产业链：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <div class="area-provinces-select">
                <div class="area-provinces-select-item" v-for="i in levelAndTags" :key="i.area" :style="{width:i.width}">
                  <div class="area-provinces-select-area">{{i.area}}：</div>
                  <tag-select class="area-provinces-select-provinces" :tags="i.provinces" v-model="i.selected" multiple></tag-select>
                </div>
              </div>
            </collapse>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">场馆地区：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <div class="area-provinces-select">
                <div class="area-provinces-select-item" v-for="i in areaAndProvinces" :key="i.area" :style="{width:i.width}">
                  <div class="area-provinces-select-area">{{i.area}}：</div>
                  <tag-select class="area-provinces-select-provinces" :tags="i.provinces" v-model="i.selected" multiple></tag-select>
                </div>
              </div>
            </collapse>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #FFFFFF;padding: 20px;margin-top: 40px;">
      <el-table :data="data.content" stripe class="large-font-table" tooltip-effect="light">
        <el-table-column prop="name" label="名称" width="340" show-overflow-tooltip>
<!--          <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/company/details',query:{id:row.id}}).href">{{row.name}}</a>-->
<!--          <a class="link" slot-scope="{row}" :href="'https://puhuibao-qyhx.zhixin.net/thirdPartyLogin?appId=sdhz&token=test&eid='+row.name" target="_blank">{{row.name}}</a>-->
          <a class="link" v-if="row.entType=='1'" slot-scope="{row}" :href="$router.resolve({path:'/company/ThirdDetails',query:{companyName:row.name}}).href">{{row.name}}</a>
          <a class="" v-else slot-scope="{row}" @onclick="return false">{{row.name}}</a>
        </el-table-column>
        <el-table-column prop="legalPerson" label="法人" width="90" show-overflow-tooltip></el-table-column>
        <el-table-column prop="registerCapital" label="注册资本" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="establishDate" label="成立日期" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="地址" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";
import TagSelect from "./TagSelect";

export default {
  name: "CompanyList",
  components: {TagSelect},
  data(){
    return {
      data:null,
      areaAndProvinces:[
        {
          area:"华东",
          provinces:["山东", "江苏", "上海", "浙江", "福建", "安徽", "江西"],
          selected:[],
          width:'40%',
        },
        {
          area:"华南",
          provinces:["广东", "广西", "海南"],
          selected:[],
          width:'30%',
        },
        {
          area:"西南",
          provinces:["四川", "云南", "重庆", "贵州", "西藏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华北",
          provinces:["河北", "北京", "天津", "内蒙古", "山西"],
          selected:[],
          width:'40%',
        },
        {
          area:"西北",
          provinces:["陕西", "新疆", "甘肃", "青海", "宁夏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华中",
          provinces:["湖南", "湖北", "河南"],
          selected:[],
          width:'30%',
        },
        {
          area:"东北",
          provinces:["辽宁", "吉林", "黑龙江"],
          selected:[],
          width:'40%',
        }
      ],
      levelAndTags:[
        {
          area:"上游",
          provinces:["专业会展主办承办公司", "协会、学会、联盟机构", "政府机构", "媒体"],
          selected:[],
          width:'100%',
        },
        {
          area:"中游",
          provinces:["会展场馆", "会展综合体", "会展酒店","体育场馆", "会馆、会所", "其他场所"],
          selected:[],
          width:'100%',
        },
        {
          area:"下游",
          provinces:["会展物流", "会展翻译", "设备租赁","搭建服务", "灯光音响", "安保服务","IT服务", "主场服务", "餐饮"
            ,"人力资源", "礼仪", "设计","其他服务"],
          selected:[],
          width:'100%',
        },
        {
          area:"其他",
          provinces:["行业监管", "会展研究"],
          selected:[],
          width:'100%',
        },
      ],
      searchParams:{
        province:null,
        tag:null,
        keyword: null,
        entType: [],
      },
      searchForm:{
        tag:[],
        province: [],
        keyword: null,
        entType: [],
      },
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      if(this.$route.query.area){
        const i=this.areaAndProvinces.find(i=>i.area===this.$route.query.area)
        if(i){
          i.selected=[...i.provinces]
        }
      }
      if(this.$route.query.level){
        console.log(this.$route.query.level)
        const i=this.levelAndTags.find(i=>i.area===this.$route.query.level)
        if(i){
          let tag= [this.$route.query.tag]
          i.selected=[...tag]
        }
      }
      await this.search()
    },
    async search(){
      const province=this.areaAndProvinces.reduce((acc,v)=>{
        acc.push(...v.selected)
        return acc
      },[])
      if(province.length===0){
        this.searchParams.province=null
      }else{
        this.searchParams.province=province
      }
      const tag=this.levelAndTags.reduce((acc,v)=>{
        acc.push(...v.selected)
        return acc
      },[])
      if(tag.length===0){
        this.searchParams.tag=null
      }else{
        this.searchParams.tag=tag
      }
      this.searchParams.keyword=this.searchForm.keyword
      await this.loadData(1)
    },
    async loadData(page){
      const params={
        ...this.searchParams,
        page:page-1,
        size:10,
      }
      this.data=(await api.listCompany(params)).data
    }
  }
}
</script>

<style scoped>
.search-form-field-label{
  width: 100px;
}
.search-form-row1{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
