<template>
  <block title="会展产业链地图">
    <div class="page-layout">
      <!-- 第一列 -->
      <div class="column1">
        <div class="section_one">上游</div>
        <div class="section_two">中游</div>
        <div class="section_three">下游</div>
      </div>

      <!-- 第二列 -->
      <div class="column">
        <div class="row">
          <div class="block_one" v-for="(block, index) in rowOneBlocks" :key="'row1-' + index" >
<!--            <div class="block_one_icon">
              <img :src="require('../assets/images/'+ block.icon)" style="width: 24px"/>
            </div>-->
            <div>{{ block.name }}</div>
            <div>
              <span class="block_num" @click="routerToList(block.name, '上游')">{{ displayValue(block.name) }}</span>
              <span @click="routerToList(block.name, '上游')">家</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="block_two" v-for="(block, index) in rowTwoBlocks" :key="'row1-' + index" >
            <div class="block_two_name">{{ block }}</div>
            <div>
              <span class="block_num" @click="routerToList(block, '中游')">{{ displayValue(block) }}</span>
              <span @click="routerToList(block, '中游')">家</span>
            </div>
          </div>
        </div>
        <div class="row-hh">
          <div class="block_three" v-for="(block, index) in rowThreeBlocks" :key="'row3-' + index">
            <div class="block_three_name">{{ block }}</div>
            <div>
              <span class="block_num" @click="routerToList(block, '下游')">{{ displayValue(block) }}</span>
              <span @click="routerToList(block, '下游')">家</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 第三列 -->
      <div class="column3">
        <div class="section_four" v-for="(block, index) in rowFourBlocks" :key="'row4-' + index">
          <div class="block_two_icon">
            <img :src="require('../assets/images/'+ block.icon)" style="width: 24px"/>
          </div>
          <div class="block_four_name">{{ block.name }}</div>
          <div class="block_four_num">
            <span class="block_num" @click="routerToList(block.name, '其他')">{{ displayValue(block.name) }}</span>
          </div>
          <div>
            <span>家</span>
          </div>
        </div>
      </div>
    </div>
<!--    <img src="../assets/images/ss/company-1.png" style="width: 100%">-->
  </block>
</template>

<script>
import Block from "./Block";
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import api from "../api";

export default {
  name: "CompanyMapBlock",
  components: {Block},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      data: null,
      rowOneBlocks: [
        {name: '专业会展主办承办公司',icon:'sy-icon-1.png'},
        {name: '协会、学会、联盟机构',icon:'sy-icon-2.png'},
        {name: '政府机构',icon:'sy-icon-3.png'},
        {name: '媒体',icon:'sy-icon-4.png'}
      ],
      rowTwoBlocks: ["会展场馆", "会展综合体", "会展酒店","体育场馆", "会管会所", "其他场所"],
      rowThreeBlocks: ["会展物流", "会展翻译", "设备租赁","搭建服务", "灯光音响", "安保服务","IT服务", "主场服务", "餐饮"
        ,"人力资源", "礼仪", "设计","其他服务"],
      rowFourBlocks: [
        {name: '行业监管',icon:'hyjg-icon.png'},
        {name: '会展研究',icon:'hzyj-icon.png'}],
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const params={
        ...this.searchParams,
      }
      this.data=(await api.getTagStat(params)).data
    },
    displayValue(blockName) {
      // 查找后端数据中的对应值
      const match = this.data.find(item => item.name === blockName);
      return match ? match.value : '0'; // 如果没找到值，返回默认值 100
    },
    routerToList(block, level){
      this.$router.push({path:'/company/list',query:{level: level, tag:block}})
    }
  }
}
</script>

<style scoped>
.page-layout {
  display: flex;
  justify-content: space-between;
  padding: 0px;
}

.column {
  display: flex;
  flex: 1;
  margin: 0 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.column1 {
  display: flex;
  margin: 0 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.column3{
  display: flex;
  margin: 0 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.section {
  background-color: #f5f5f5;
  margin-bottom: 15px;
  padding: 20px;
  text-align: center;
  border-radius: 4px;
}
.section_one {
  width: 52px;
  height: 80px;
  gap: 16px;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #EFE6FF;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #802ADC;

  display: flex;
  justify-content: flex-end; /* 垂直居中 */
  align-items: center; /* 水平居中 */

  writing-mode: vertical-rl; /* 使文字竖着显示 */
  letter-spacing: 14px; /* 调整字与字之间的间距 */
}
.section_two{
  width: 52px;
  height: 80px;
  gap: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  background: #FCEDE6;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #FA7632;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;

  writing-mode: vertical-rl; /* 使文字竖着显示 */
  letter-spacing: 14px; /* 调整字与字之间的间距 */
}
.section_three{
  width: 52px;
  height: 174px;
  gap: 16px;
  border-radius: 8px;
  background: #E6F6FE;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #00B2D1;

  display: flex;
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */

  writing-mode: vertical-rl; /* 使文字竖着显示 */
  letter-spacing: 12px; /* 调整字与字之间的间距 */
}

.section_four{
  width: 66px;
  height: 171px;
  border-radius: 8px;
  font-family: PingFang SC;
  font-weight: 400;
  text-align: center;
  text-decoration-skip-ink: none;

  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;

  writing-mode: vertical-rl; /* 使文字竖着显示 */
  letter-spacing: 2px; /* 调整字与字之间的间距 */
  background: linear-gradient(180deg, #FFFFFF 0%, #EEF6FE 100%);
  border: 1px solid #B2C7DD;
  box-shadow: 0px 4px 6px 0px #EEF4FA;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.block_num{
  color: #0356AD;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: center;
  cursor: pointer;
}

.block_one{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-right: 16px;
  color: #4D4D4D;
  text-align: center;
  height: 78.4px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.8px;
  text-align: center;
  background: linear-gradient(180deg, #FFFFFF 0%, #EEF6FE 100%);
  border: 1px solid #B2C7DD;
  box-shadow: 0px 4px 6px 0px #EEF4FA;
}
.block_one:last-child {
  margin-right: 0px;
}
.block_one_icon{
  margin-top: 16px;
}
.block_two{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-right: 16px;
  color: #4D4D4D;
  text-align: center;
  height: 78.4px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.8px;
  text-align: center;
  background: linear-gradient(180deg, #FFFFFF 0%, #EEF6FE 100%);
  border: 1px solid #B2C7DD;
  box-shadow: 0px 4px 6px 0px #EEF4FA;
}
.block_two:last-child {
  margin-right: 0px;
}
.block_two_name{
  margin-top: 2px;
}
.block_two_icon{
  margin-bottom: 2px;
}

.block_three{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #4D4D4D;
  text-align: center;
  height: 78.4px;
  width: 129.7px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.8px;
  text-align: center;
  background: linear-gradient(180deg, #FFFFFF 0%, #EEF6FE 100%);
  border: 1px solid #B2C7DD;
  box-shadow: 0px 4px 6px 0px #EEF4FA;
}
.block_three:last-child {
  margin-right: 0px;
}
.block_three_name{
  margin-top: 2px;
  display: flex;
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
}
.block_four_name{
  width: 52px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #4D4D4D;
  display: flex;
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  writing-mode: vertical-rl; /* 使文字竖着显示 */
  letter-spacing: 4px; /* 调整字与字之间的间距 */
}
.block_four_num{
  width: 52px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #802ADC;
  display: flex;
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  writing-mode: horizontal-tb; /* 使文字水平显示 */
}

.block_red{
  flex: 1;
  background-color: #cd9cb0;
  color: #1F7AD9;
  padding: 20px;
  margin-right: 10px;
  border-radius: 4px;
  text-align: center;
  border: 1px dashed #1F7AD9; /* 设置虚线边框，颜色为白色，宽度为 2px */
}
.block_blue{
  flex: 1;
  background-color: #c2cee0;
  color: #1F7AD9;
  padding: 20px;
  margin-right: 10px;
  border-radius: 4px;
  text-align: center;
  border: 1px dashed #1F7AD9; /* 设置虚线边框，颜色为白色，宽度为 2px */
}

.block:last-child {
  margin-right: 0;
}
.row-hh{
  display: flex;
  align-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1 16%;
  box-sizing: border-box;
  gap: 14px 20px;
  justify-content: flex-start;
  align-items: stretch;
}
</style>
