<template>
  <div class="page-content" v-if="companyName!=null && companyName!='' && message==null" style="margin: 0px auto 40px auto">
    <iframe-block :iframeId="'iframe'"
                  v-show="true"
                  :src="url+companyName"
    ></iframe-block>
  </div>
  <div class="no-found-company" v-else>{{message}}</div>
</template>

<script>
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import IframeBlock from "./IframeBlock";
export default {
  name: "ThirdCompanyDetails",
  components:{
    IframeBlock
  },
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      message: null,
      loading: false,
      url: 'https://puhuibao-qyhx.zhixin.net/thirdPartyLogin?appId=sdhz&token=test&eid=',
      companyName: '',
    }
  },
  created() {
    this.init()
  },
  computed:{
  },
  methods: {
    async init(){
      console.log(this.$route.query.companyName)
      if(this.$route.query.companyName===undefined){
        this.companyName = null
      }else{
        this.companyName = this.$route.query.companyName
      }
    },
  }
}
</script>

<style scoped>

</style>
