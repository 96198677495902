export const companyFinanceData = [{
    "income": 6.638309549E7,
    "grossProfitMargin": 13.865,
    "stockName": "励翔会展",
    "year": 2020,
    "industry": "会务服务",
    "asset": 6.35295797E7,
    "stockCode": "872510.NQ",
    "netProfit": -2440308.72,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0872510&stockCode=872510&announcementId=1210062116&announcementTime=2021-04-27"
}, {
    "income": 4.479492923E7,
    "grossProfitMargin": 0.8942,
    "stockName": "励翔会展",
    "year": 2021,
    "industry": "会务服务",
    "asset": 3.262986228E7,
    "stockCode": "872510.NQ",
    "netProfit": -9308714.32,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0872510&stockCode=872510&announcementId=1213187206&announcementTime=2022-04-27"
}, {
    "income": 5.499548639E7,
    "grossProfitMargin": 15.8959,
    "stockName": "励翔会展",
    "year": 2022,
    "industry": "会务服务",
    "asset": 4.631083025E7,
    "stockCode": "872510.NQ",
    "netProfit": -5165842.83,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0872510&stockCode=872510&announcementId=1216514243&announcementTime=2023-04-20"
}, {
    "income": 2.98215299862E9,
    "grossProfitMargin": 10.5168,
    "stockName": "兰生股份",
    "year": 2020,
    "industry": "展览全产业链",
    "asset": 4.5610853084399E9,
    "stockCode": "600826.SH",
    "netProfit": 1.4461578556E8,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=sse&orgId=gssh0600826&stockCode=600826&announcementId=1209712825&announcementTime=2021-04-17"
}, {
    "income": 9.2493319458E8,
    "grossProfitMargin": 29.6451,
    "stockName": "兰生股份",
    "year": 2021,
    "industry": "展览全产业链",
    "asset": 5.22237286551E9,
    "stockCode": "600826.SH",
    "netProfit": 1.2861296456E8,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=sse&orgId=gssh0600826&stockCode=600826&announcementId=1212854339&announcementTime=2022-04-09"
}, {
    "income": 7.7308810857E8,
    "grossProfitMargin": 37.7259,
    "stockName": "兰生股份",
    "year": 2022,
    "industry": "展览全产业链",
    "asset": 5.76063720638E9,
    "stockCode": "600826.SH",
    "netProfit": 1.0957528569E8,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=sse&orgId=gssh0600826&stockCode=600826&announcementId=1216615161&announcementTime=2023-04-27"
}, {
    "income": 1.02427265472E9,
    "grossProfitMargin": 31.3234,
    "stockName": "易尚退",
    "year": 2020,
    "industry": "展示工程",
    "asset": 4.1013112557399E9,
    "stockCode": "002751.SZ",
    "netProfit": 7.123394494E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=9900023632&stockCode=002751&announcementId=1209775777&announcementTime=2021-04-23"
}, {
    "income": 8.0366136414E8,
    "grossProfitMargin": -12.9592,
    "stockName": "易尚退",
    "year": 2021,
    "industry": "展示工程",
    "asset": 3.5812565003299E9,
    "stockCode": "002751.SZ",
    "netProfit": -5.5736157842E8,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=9900023632&stockCode=002751&announcementId=1213266377&announcementTime=2022-04-30"
}, {
    "income": 2.0180647926E8,
    "grossProfitMargin": 33.5453,
    "stockName": "易尚退",
    "year": 2022,
    "industry": "展示工程",
    "asset": 3.1662651069099E9,
    "stockCode": "002751.SZ",
    "netProfit": -2.5133650942E8,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=9900023632&stockCode=002751&announcementId=1216703752&announcementTime=2023-04-29"
}, {
    "income": 2.25630188851E9,
    "grossProfitMargin": 34.8787,
    "stockName": "风语筑",
    "year": 2020,
    "industry": "展示工程",
    "asset": 4.3288347227299E9,
    "stockCode": "603466.SH",
    "netProfit": 3.4251087526E8,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=sse&orgId=9900033216&stockCode=603466&announcementId=1209703586&announcementTime=2021-04-16"
}, {
    "income": 2.93990628247E9,
    "grossProfitMargin": 32.9558,
    "stockName": "风语筑",
    "year": 2021,
    "industry": "展示工程",
    "asset": 4.4037364574799E9,
    "stockCode": "603466.SH",
    "netProfit": 4.3883592592E8,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=sse&orgId=9900033216&stockCode=603466&announcementId=1213218491&announcementTime=2022-04-29"
}, {
    "income": 1.68190448558E9,
    "grossProfitMargin": 27.1344,
    "stockName": "风语筑",
    "year": 2022,
    "industry": "展示工程",
    "asset": 5.11643990514E9,
    "stockCode": "603466.SH",
    "netProfit": 6.604417976E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=sse&orgId=9900033216&stockCode=603466&announcementId=1216437785&announcementTime=2023-04-18"
}, {
    "income": 1.3516292717E8,
    "grossProfitMargin": 17.8274,
    "stockName": "华凯易佰",
    "year": 2020,
    "industry": "展示工程",
    "asset": 9.122297430499E8,
    "stockCode": "300592.SZ",
    "netProfit": -6.248336482E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=9900030303&stockCode=300592&announcementId=1209485360&announcementTime=2021-03-31"
}, {
    "income": 2.07486149134E9,
    "grossProfitMargin": 37.2389,
    "stockName": "华凯易佰",
    "year": 2021,
    "industry": "展示工程",
    "asset": 2.9690422983499E9,
    "stockCode": "300592.SZ",
    "netProfit": -8.195165931E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=9900030303&stockCode=300592&announcementId=1213019703&announcementTime=2022-04-22"
}, {
    "income": 4.4170240294E9,
    "grossProfitMargin": 37.925,
    "stockName": "华凯易佰",
    "year": 2022,
    "industry": "展示工程",
    "asset": 3.1283782657699E9,
    "stockCode": "300592.SZ",
    "netProfit": 2.4382680168E8,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=9900030303&stockCode=300592&announcementId=1216474923&announcementTime=2023-04-20"
}, {
    "income": 9.435330535E7,
    "grossProfitMargin": 37.2417,
    "stockName": "米奥会展",
    "year": 2020,
    "industry": "展览主办",
    "asset": 5.3056835942E8,
    "stockCode": "300795.SZ",
    "netProfit": -6.6027888E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=gfbj0831822&stockCode=300795&announcementId=1209814212&announcementTime=2021-04-27"
}, {
    "income": 1.8164093064E8,
    "grossProfitMargin": 56.7749,
    "stockName": "米奥会展",
    "year": 2021,
    "industry": "展览主办",
    "asset": 5.228833740899E8,
    "stockCode": "300795.SZ",
    "netProfit": -5.939877724E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=gfbj0831822&stockCode=300795&announcementId=1212643234&announcementTime=2022-03-23"
}, {
    "income": 3.4831296172E8,
    "grossProfitMargin": 49.3411,
    "stockName": "米奥会展",
    "year": 2022,
    "industry": "展览主办",
    "asset": 5.9548412944E8,
    "stockCode": "300795.SZ",
    "netProfit": 5.1145914E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=gfbj0831822&stockCode=300795&announcementId=1216456132&announcementTime=2023-04-19"
}, {
    "income": 4.244897341E7,
    "grossProfitMargin": 52.9506,
    "stockName": "名洋数字",
    "year": 2020,
    "industry": "展示工程",
    "asset": 6.736605848E7,
    "stockCode": "831946.NQ",
    "netProfit": 1127745.73,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0831946&stockCode=831946&announcementId=1210020776&announcementTime=2021-04-22"
}, {
    "income": 1.0225606892E8,
    "grossProfitMargin": 42.9277,
    "stockName": "名洋数字",
    "year": 2021,
    "industry": "展示工程",
    "asset": 8.593940445E7,
    "stockCode": "831946.NQ",
    "netProfit": 1.104075147E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0831946&stockCode=831946&announcementId=1213187178&announcementTime=2022-04-27"
}, {
    "income": 1.2514240286E8,
    "grossProfitMargin": 31.6208,
    "stockName": "名洋数字",
    "year": 2022,
    "industry": "展示工程",
    "asset": 1.4013708587E8,
    "stockCode": "831946.NQ",
    "netProfit": 1.6556754E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0831946&stockCode=831946&announcementId=1216604368&announcementTime=2023-04-25"
}, {
    "income": 3.6465641797E8,
    "grossProfitMargin": 24.6603,
    "stockName": "卡司通",
    "year": 2020,
    "industry": "展示工程",
    "asset": 4.5692829567E8,
    "stockCode": "832971.NQ",
    "netProfit": -1.364073788E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0832971&stockCode=832971&announcementId=1210031169&announcementTime=2021-04-23"
}, {
    "income": 4.4338578252E8,
    "grossProfitMargin": 22.1478,
    "stockName": "卡司通",
    "year": 2021,
    "industry": "展示工程",
    "asset": 5.212548276E8,
    "stockCode": "832971.NQ",
    "netProfit": -2.283783936E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0832971&stockCode=832971&announcementId=1213207415&announcementTime=2022-04-28"
}, {
    "income": 3.7462182549E8,
    "grossProfitMargin": 24.2361,
    "stockName": "卡司通",
    "year": 2022,
    "industry": "展示工程",
    "asset": 4.7104851356E8,
    "stockCode": "832971.NQ",
    "netProfit": 7263594.9,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0832971&stockCode=832971&announcementId=1216727459&announcementTime=2023-04-27"
}, {
    "income": 2.0355672305E8,
    "grossProfitMargin": 21.8644,
    "stockName": "振威会展",
    "year": 2020,
    "industry": "展览主办",
    "asset": 3.4875899491E8,
    "stockCode": "834316.NQ",
    "netProfit": -4.25211914E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0834316&stockCode=834316&announcementId=1210077973&announcementTime=2021-04-29"
}, {
    "income": 2.5060945888E8,
    "grossProfitMargin": 35.065,
    "stockName": "振威会展",
    "year": 2021,
    "industry": "展览主办",
    "asset": 3.510037057099E8,
    "stockCode": "834316.NQ",
    "netProfit": -1.373189554E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0834316&stockCode=834316&announcementId=1213131169&announcementTime=2022-04-26"
}, {
    "income": 2.4691317259E8,
    "grossProfitMargin": 25.2546,
    "stockName": "振威会展",
    "year": 2022,
    "industry": "展览主办",
    "asset": 3.5350837691E8,
    "stockCode": "834316.NQ",
    "netProfit": -1.539454955E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0834316&stockCode=834316&announcementId=1216698703&announcementTime=2023-04-27"
}, {
    "income": 1.497218748E7,
    "grossProfitMargin": 36.625,
    "stockName": "黑油数字",
    "year": 2020,
    "industry": "展示工程",
    "asset": 1.111674445E7,
    "stockCode": "835058.NQ",
    "netProfit": 1858556.57,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0835058&stockCode=835058&announcementId=1210057739&announcementTime=2021-04-27"
}, {
    "income": 1.105508001E7,
    "grossProfitMargin": 36.5544,
    "stockName": "黑油数字",
    "year": 2021,
    "industry": "展示工程",
    "asset": 1.241156909E7,
    "stockCode": "835058.NQ",
    "netProfit": -616837.28,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0835058&stockCode=835058&announcementId=1213184992&announcementTime=2022-04-27"
}, {
    "income": 1.311706277E7,
    "grossProfitMargin": 40.6673,
    "stockName": "黑油数字",
    "year": 2022,
    "industry": "展示工程",
    "asset": 2.04684291E7,
    "stockCode": "835058.NQ",
    "netProfit": 250147.81,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0835058&stockCode=835058&announcementId=1216609940&announcementTime=2023-04-25"
}, {
    "income": 7.12898268E7,
    "grossProfitMargin": 19.1061,
    "stockName": "蓝色方略",
    "year": 2020,
    "industry": "会务服务",
    "asset": 1.463822095399E8,
    "stockCode": "835675.NQ",
    "netProfit": -2.677020381E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0835675&stockCode=835675&announcementId=1210063900&announcementTime=2021-04-28"
}, {
    "income": 7.319477642E7,
    "grossProfitMargin": 36.8979,
    "stockName": "蓝色方略",
    "year": 2021,
    "industry": "会务服务",
    "asset": 1.107378645E8,
    "stockCode": "835675.NQ",
    "netProfit": -2.686750619E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0835675&stockCode=835675&announcementId=1213117309&announcementTime=2022-04-25"
}, {
    "income": 8.968385654E7,
    "grossProfitMargin": 34.2801,
    "stockName": "蓝色方略",
    "year": 2022,
    "industry": "会务服务",
    "asset": 1.0799288745E8,
    "stockCode": "835675.NQ",
    "netProfit": -1.921239735E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0835675&stockCode=835675&announcementId=1216586198&announcementTime=2023-04-25"
}, {
    "income": 5.331770861E7,
    "grossProfitMargin": 32.8348,
    "stockName": "芯联创展",
    "year": 2020,
    "industry": "会展科技",
    "asset": 3.644609255E7,
    "stockCode": "836205.NQ",
    "netProfit": 794955.88,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0836205&stockCode=836205&announcementId=1210040696&announcementTime=2021-04-26"
}, {
    "income": 5.413756264E7,
    "grossProfitMargin": 43.6908,
    "stockName": "芯联创展",
    "year": 2021,
    "industry": "会展科技",
    "asset": 4.005733777E7,
    "stockCode": "836205.NQ",
    "netProfit": 1070823.57,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0836205&stockCode=836205&announcementId=1213133066&announcementTime=2022-04-26"
}, {
    "income": 5.056184841E7,
    "grossProfitMargin": 34.6433,
    "stockName": "芯联创展",
    "year": 2022,
    "industry": "会展科技",
    "asset": 4.713554623E7,
    "stockCode": "836205.NQ",
    "netProfit": 580389.91,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0836205&stockCode=836205&announcementId=1216491172&announcementTime=2023-04-20"
}, {
    "income": 1.17640054328E9,
    "grossProfitMargin": 23.8672,
    "stockName": "中青博联",
    "year": 2020,
    "industry": "会务服务",
    "asset": 9.870355880099E8,
    "stockCode": "837784.NQ",
    "netProfit": 4035200.18,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0837784&stockCode=837784&announcementId=1209446050&announcementTime=2021-03-24"
}, {
    "income": 1.73161610332E9,
    "grossProfitMargin": 26.2925,
    "stockName": "中青博联",
    "year": 2021,
    "industry": "会务服务",
    "asset": 1.0611097971599E9,
    "stockCode": "837784.NQ",
    "netProfit": 6.65360331E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0837784&stockCode=837784&announcementId=1212741779&announcementTime=2022-03-30"
}, {
    "income": 1.04658570918E9,
    "grossProfitMargin": 27.1135,
    "stockName": "中青博联",
    "year": 2022,
    "industry": "会务服务",
    "asset": 9.0976246373E8,
    "stockCode": "837784.NQ",
    "netProfit": 3271104.91,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0837784&stockCode=837784&announcementId=1216300562&announcementTime=2023-03-31"
}, {
    "income": 6.041633684E7,
    "grossProfitMargin": 10.4078,
    "stockName": "昆仑股份",
    "year": 2020,
    "industry": "会展科技",
    "asset": 1.0698938653E8,
    "stockCode": "836036.NQ",
    "netProfit": -6.065962419E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0836036&stockCode=836036&announcementId=1210074434&announcementTime=2021-04-28"
}, {
    "income": 6.474206428E7,
    "grossProfitMargin": 43.0939,
    "stockName": "昆仑股份",
    "year": 2021,
    "industry": "会展科技",
    "asset": 9.333987165E7,
    "stockCode": "836036.NQ",
    "netProfit": -2.086427363E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0836036&stockCode=836036&announcementId=1212982056&announcementTime=2022-04-19"
}, {
    "income": 3.55256312E7,
    "grossProfitMargin": 36.7338,
    "stockName": "昆仑股份",
    "year": 2022,
    "industry": "会展科技",
    "asset": 7.625455962E7,
    "stockCode": "836036.NQ",
    "netProfit": -1.763321075E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0836036&stockCode=836036&announcementId=1216480052&announcementTime=2023-04-19"
}, {
    "income": 2.8646749632E8,
    "grossProfitMargin": 27.2588,
    "stockName": "广百展贸",
    "year": 2020,
    "industry": "展览主办",
    "asset": 7.5115281658E8,
    "stockCode": "870079.NQ",
    "netProfit": 3.747078117E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0870079&stockCode=870079&announcementId=1210025374&announcementTime=2021-04-23"
}, {
    "income": 2.3771146093E8,
    "grossProfitMargin": 25.1395,
    "stockName": "广百展贸",
    "year": 2021,
    "industry": "展览主办",
    "asset": 1.0798844958099E9,
    "stockCode": "870079.NQ",
    "netProfit": 9209771.81,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0870079&stockCode=870079&announcementId=1212816527&announcementTime=2022-04-01"
}, {
    "income": 1.4885112547E8,
    "grossProfitMargin": 9.7038,
    "stockName": "广百展贸",
    "year": 2022,
    "industry": "展览主办",
    "asset": 8.619682290399E8,
    "stockCode": "870079.NQ",
    "netProfit": -1.093694691E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0870079&stockCode=870079&announcementId=1216328738&announcementTime=2023-04-04"
}, {
    "income": 1.0105225895E8,
    "grossProfitMargin": 20.1418,
    "stockName": "骏宇文博",
    "year": 2020,
    "industry": "展示工程",
    "asset": 1.3986229231E8,
    "stockCode": "870635.NQ",
    "netProfit": 5824131.34,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0870635&stockCode=870635&announcementId=1209744067&announcementTime=2021-04-20"
}, {
    "income": 1.8470613894E8,
    "grossProfitMargin": 23.5598,
    "stockName": "骏宇文博",
    "year": 2021,
    "industry": "展示工程",
    "asset": 1.6647130015E8,
    "stockCode": "870635.NQ",
    "netProfit": 1.739521125E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0870635&stockCode=870635&announcementId=1212982556&announcementTime=2022-04-19"
}, {
    "income": 8.624600141E7,
    "grossProfitMargin": 35.2198,
    "stockName": "骏宇文博",
    "year": 2022,
    "industry": "展示工程",
    "asset": 1.8012384781E8,
    "stockCode": "870635.NQ",
    "netProfit": 7526652.32,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0870635&stockCode=870635&announcementId=1216564054&announcementTime=2023-04-24"
}, {
    "income": 3.0033028675E8,
    "grossProfitMargin": 31.1808,
    "stockName": "创捷传媒",
    "year": 2020,
    "industry": "展示工程",
    "asset": 3.777576166399E8,
    "stockCode": "837761.NQ",
    "netProfit": 2.506779893E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0837761&stockCode=837761&announcementId=1210015132&announcementTime=2021-04-21"
}, {
    "income": 3.0051935698E8,
    "grossProfitMargin": 36.1114,
    "stockName": "创捷传媒",
    "year": 2021,
    "industry": "展示工程",
    "asset": 4.0724724947E8,
    "stockCode": "837761.NQ",
    "netProfit": 4.879381143E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0837761&stockCode=837761&announcementId=1213196306&announcementTime=2022-04-28"
}, {
    "income": 8.65308075E7,
    "grossProfitMargin": 37.2526,
    "stockName": "创捷传媒",
    "year": 2022,
    "industry": "展示工程",
    "asset": 3.229577943299E8,
    "stockCode": "837761.NQ",
    "netProfit": -1.369693545E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0837761&stockCode=837761&announcementId=1216640087&announcementTime=2023-04-26"
}, {
    "income": 1.1008130668E8,
    "grossProfitMargin": 20.0254,
    "stockName": "东恒会展",
    "year": 2020,
    "industry": "展示工程",
    "asset": 8.012584493E7,
    "stockCode": "871805.NQ",
    "netProfit": -3475486.03,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=9900035655&stockCode=871805&announcementId=1210059288&announcementTime=2021-04-27"
}, {
    "income": 1.3706365713E8,
    "grossProfitMargin": 16.5949,
    "stockName": "东恒会展",
    "year": 2021,
    "industry": "展示工程",
    "asset": 8.429795933E7,
    "stockCode": "871805.NQ",
    "netProfit": 3882646.62,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=9900035655&stockCode=871805&announcementId=1213128990&announcementTime=2022-04-26"
}, {
    "income": 8.822092222E7,
    "grossProfitMargin": 13.9218,
    "stockName": "东恒会展",
    "year": 2022,
    "industry": "展示工程",
    "asset": 8.312838528E7,
    "stockCode": "871805.NQ",
    "netProfit": -5780941.47,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=9900035655&stockCode=871805&announcementId=1216641337&announcementTime=2023-04-26"
}, {
    "income": 7.964740816E7,
    "grossProfitMargin": 13.9928,
    "stockName": "三博会展",
    "year": 2020,
    "industry": "会务服务",
    "asset": 4.411792533E7,
    "stockCode": "871884.NQ",
    "netProfit": 2434027.9,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0871884&stockCode=871884&announcementId=1210057861&announcementTime=2021-04-27"
}, {
    "income": 2.731927683E7,
    "grossProfitMargin": 36.6604,
    "stockName": "三博会展",
    "year": 2021,
    "industry": "会务服务",
    "asset": 7.832401547E7,
    "stockCode": "871884.NQ",
    "netProfit": 2162304.1,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0871884&stockCode=871884&announcementId=1213183670&announcementTime=2022-04-27"
}, {
    "income": 5.833329255E7,
    "grossProfitMargin": 31.1047,
    "stockName": "三博会展",
    "year": 2022,
    "industry": "会务服务",
    "asset": 9.52393203499E7,
    "stockCode": "871884.NQ",
    "netProfit": 4668998.89,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0871884&stockCode=871884&announcementId=1216717540&announcementTime=2023-04-27"
}, {
    "income": 1.5537224337E8,
    "grossProfitMargin": 18.8826,
    "stockName": "瑞美股份",
    "year": 2020,
    "industry": "会务服务",
    "asset": 1.943514225999E8,
    "stockCode": "871982.NQ",
    "netProfit": 365335.73,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0871982&stockCode=871982&announcementId=1209626708&announcementTime=2021-03-31"
}, {
    "income": 1.3898137913E8,
    "grossProfitMargin": 13.1942,
    "stockName": "瑞美股份",
    "year": 2021,
    "industry": "会务服务",
    "asset": 1.484095872299E8,
    "stockCode": "871982.NQ",
    "netProfit": -5954539.1,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0871982&stockCode=871982&announcementId=1212946573&announcementTime=2022-04-15"
}, {
    "income": 3.890036731E7,
    "grossProfitMargin": 8.3958,
    "stockName": "瑞美股份",
    "year": 2022,
    "industry": "会务服务",
    "asset": 8.646667225E7,
    "stockCode": "871982.NQ",
    "netProfit": -1.411068829E7,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0871982&stockCode=871982&announcementId=1216410577&announcementTime=2023-04-14"
}, {
    "income": 8.105667714E7,
    "grossProfitMargin": 10.1519,
    "stockName": "佰锐博雅",
    "year": 2020,
    "industry": "展示工程",
    "asset": 5.249388376E7,
    "stockCode": "872937.NQ",
    "netProfit": 1733093.88,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0872937&stockCode=872937&announcementId=1210077375&announcementTime=2021-04-29"
}, {
    "income": 8.348074827E7,
    "grossProfitMargin": 10.4996,
    "stockName": "佰锐博雅",
    "year": 2021,
    "industry": "展示工程",
    "asset": 4.653054175E7,
    "stockCode": "872937.NQ",
    "netProfit": 2338819.99,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0872937&stockCode=872937&announcementId=1213203585&announcementTime=2022-04-28"
}, {
    "income": 7.397780751E7,
    "grossProfitMargin": 11.8354,
    "stockName": "佰锐博雅",
    "year": 2022,
    "industry": "展示工程",
    "asset": 5.961551486E7,
    "stockCode": "872937.NQ",
    "netProfit": 2403835.45,
    "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0872937&stockCode=872937&announcementId=1216717786&announcementTime=2023-04-27"
},
    {
        "industry": "展示工程",
        "stockCode": "872937.NQ",
        "stockName": "佰锐博雅",
        "asset": 7714.6446*10000,
        "income": 9282.897*10000,
        "netProfit": 212.6377*10000,
        "grossProfitMargin": 12.0631*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0872937&stockCode=872937&announcementId=1219999017&announcementTime=2024-04-29",
        "year": 2023
    },
    {
        "industry": "展示工程",
        "stockCode": "837761.NQ",
        "stockName": "创捷传媒",
        "asset": 32330.9722*10000,
        "income": 10817.9961*10000,
        "netProfit": 1271.7246*10000,
        "grossProfitMargin": 31.6212*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0837761&stockCode=837761&announcementId=1219889119&announcementTime=2024-04-25",
        "year": 2023
    },
    {
        "industry": "展示工程",
        "stockCode": "871805.NQ",
        "stockName": "东恒会展",
        "asset": 10108.6521*10000,
        "income": 14017.7605*10000,
        "netProfit": 848.342*10000,
        "grossProfitMargin": 18.5163*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=9900035655&stockCode=871805&announcementId=1219846684&announcementTime=2024-04-25",
        "year": 2023
    },
    {
        "industry": "展示工程",
        "stockCode": "603466.SH",
        "stockName": "风语筑",
        "asset": 491312.4921*10000,
        "income": 235049.6947*10000,
        "netProfit": 28235.6777*10000,
        "grossProfitMargin": 29.8859*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=sse&orgId=9900033216&stockCode=603466&announcementId=1219835632&announcementTime=2024-04-26",
        "year": 2023
    },
    {
        "industry": "展览主办",
        "stockCode": "870079.NQ",
        "stockName": "广百展贸",
        "asset": 92396.3082*10000,
        "income": 24844.1922*10000,
        "netProfit": 2751.0775*10000,
        "grossProfitMargin": 31.836*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0870079&stockCode=870079&announcementId=1219504492&announcementTime=2024-04-02",
        "year": 2023
    },
    {
        "industry": "展示工程",
        "stockCode": "835058.NQ",
        "stockName": "黑油数字",
        "asset": 2346.2709*10000,
        "income": 1310.356*10000,
        "netProfit": 31.3388*10000,
        "grossProfitMargin": 61.8471*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0835058&stockCode=835058&announcementId=1219518126&announcementTime=2024-04-03",
        "year": 2023
    },
    {
        "industry": "展示工程",
        "stockCode": "300592.SZ",
        "stockName": "华凯易佰",
        "asset": 325611.6616*10000,
        "income": 651786.0595*10000,
        "netProfit": 34857.0601*10000,
        "grossProfitMargin": 36.9573*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=9900030303&stockCode=300592&announcementId=1219662505&announcementTime=2024-04-19",
        "year": 2023
    },
    {
        "industry": "展示工程",
        "stockCode": "870635.NQ",
        "stockName": "骏宇文博",
        "asset": 17228.0115*10000,
        "income": 9932.1466*10000,
        "netProfit": 180.7486*10000,
        "grossProfitMargin": 25.0425*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0870635&stockCode=870635&announcementId=1219635213&announcementTime=2024-04-16",
        "year": 2023
    },
    {
        "industry": "展示工程",
        "stockCode": "832971.NQ",
        "stockName": "卡司通",
        "asset": 54653.8833*10000,
        "income": 58438.0028*10000,
        "netProfit": 3063.7513*10000,
        "grossProfitMargin": 25.5402*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0832971&stockCode=832971&announcementId=1219805127&announcementTime=2024-04-24",
        "year": 2023
    },
    {
        "industry": "会展科技",
        "stockCode": "836036.NQ",
        "stockName": "昆仑股份",
        "asset": 8803.0683*10000,
        "income": 8623.8145*10000,
        "netProfit": 102.1089*10000,
        "grossProfitMargin": 44.9004*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0836036&stockCode=836036&announcementId=1219673344&announcementTime=2024-04-18",
        "year": 2023
    },
    {
        "industry": "展览全产业链",
        "stockCode": "600826.SH",
        "stockName": "兰生股份",
        "asset": 552686.3666*10000,
        "income": 142195.7784*10000,
        "netProfit": 31524.2539*10000,
        "grossProfitMargin": 31.903*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=sse&orgId=gssh0600826&stockCode=600826&announcementId=1216615161&announcementTime=2023-04-27",
        "year": 2023
    },
    {
        "industry": "会务服务",
        "stockCode": "835675.NQ",
        "stockName": "蓝色方略",
        "asset": 11806.5994*10000,
        "income": 10639.0581*10000,
        "netProfit": 10.6563*10000,
        "grossProfitMargin": 30.1595*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0835675&stockCode=835675&announcementId=1219888398&announcementTime=2024-04-25",
        "year": 2023
    },
    {
        "industry": "会务服务",
        "stockCode": "872510.NQ",
        "stockName": "励翔会展",
        "asset": 4684.2444*10000,
        "income": 7896.5046*10000,
        "netProfit": -404.0987*10000,
        "grossProfitMargin": 12.0782*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0872510&stockCode=872510&announcementId=1219968354&announcementTime=2024-04-29",
        "year": 2023
    },
    {
        "industry": "展览主办",
        "stockCode": "300795.SZ",
        "stockName": "米奥会展",
        "asset": 86844.9405*10000,
        "income": 83497.0775*10000,
        "netProfit": 19726.0392*10000,
        "grossProfitMargin": 50.0861*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=szse&orgId=gfbj0831822&stockCode=300795&announcementId=1219411843&announcementTime=2024-03-27",
        "year": 2023
    },
    {
        "industry": "展示工程",
        "stockCode": "831946.NQ",
        "stockName": "名洋数字",
        "asset": 25315.9365*10000,
        "income": 18105.0115*10000,
        "netProfit": 2229.7586*10000,
        "grossProfitMargin": 45.5076*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0831946&stockCode=831946&announcementId=1219952408&announcementTime=2024-04-26",
        "year": 2023
    },
    {
        "industry": "会务服务",
        "stockCode": "871982.NQ",
        "stockName": "瑞美股份",
        "asset": 7628.0036*10000,
        "income": 6974.1811*10000,
        "netProfit": -984.88*10000,
        "grossProfitMargin": 10.05*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0871982&stockCode=871982&announcementId=1219854328&announcementTime=2024-04-25",
        "year": 2023
    },
    {
        "industry": "会务服务",
        "stockCode": "871884.NQ",
        "stockName": "三博会展",
        "asset": 13948.3681*10000,
        "income": 16644.2542*10000,
        "netProfit": 804.76*10000,
        "grossProfitMargin": 15.2992*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0871884&stockCode=871884&announcementId=1219936128&announcementTime=2024-04-26",
        "year": 2023
    },
    {
        "industry": "会展科技",
        "stockCode": "836205.NQ",
        "stockName": "芯联创展",
        "asset": 5472.6575*10000,
        "income": 5502.4288*10000,
        "netProfit": 46.5959*10000,
        "grossProfitMargin": 31.5194*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0836205&stockCode=836205&announcementId=1219772363&announcementTime=2024-04-23",
        "year": 2023
    },
    {
        "industry": "展示工程",
        "stockCode": "002751.SZ",
        "stockName": "易尚退",
        "asset": null,
        "income": null,
        "netProfit": null,
        "grossProfitMargin": null,
        "url": "退市",
        "year": 2023
    },
    {
        "industry": "展览主办",
        "stockCode": "834316.NQ",
        "stockName": "振威会展",
        "asset": 42976.3359*10000,
        "income": 46144.2698*10000,
        "netProfit": 5235.9713*10000,
        "grossProfitMargin": 39.3077*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0834316&stockCode=834316&announcementId=1219929573&announcementTime=2024-04-26",
        "year": 2023
    },
    {
        "industry": "会务服务",
        "stockCode": "837784.NQ",
        "stockName": "中青博联",
        "asset": 123605.8465*10000,
        "income": 218721.1968*10000,
        "netProfit": 6056.9331*10000,
        "grossProfitMargin": 19.4142*10000,
        "url": "http://www.cninfo.com.cn/new/disclosure/detail?plate=neeq&orgId=gfbj0837784&stockCode=837784&announcementId=1219504484&announcementTime=2024-04-02",
        "year": 2023
    }
]


export function statCompanyFinanceDataByIndustry(year, index) {
    const map = new Map()
    companyFinanceData.forEach(i => {
        if (i.year !== year) {
            return
        }
        const value = map.get(i.industry)
        if (value === undefined) {
            map.set(i.industry, {sum: i[index], count: 1})
        } else {
            map.set(i.industry, {sum: value.sum + i[index], count: value.count + 1})
        }
    })
    const result = []
    for (const kv of map.entries()) {
        result.push({key: kv[0], sum: kv[1].sum, count: kv[1].count, avg: kv[1].sum / kv[1].count})
    }
    return result
}

export function getCompanyFinanceDataByIndex(years, index) {
    const map = new Map()
    companyFinanceData.forEach(i => {
        if (years.indexOf(i.year) === -1) {
            return
        }
        const value = map.get(i.stockCode)
        if (value === undefined) {
            map.set(i.stockCode, {
                stockCode: i.stockCode,
                stockName: i.stockName,
                industry: i.industry,
                ['url' + i.year]: i.url,
                ['' + i.year]: i[index]
            })
        } else {
            value['url' + i.year] = i.url
            value['' + i.year] = i[index]
        }
    })
    return [...map.values()].sort((a, b) => a.stockName.localeCompare(b.stockName))
}
